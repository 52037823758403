<template>
  <div>
    <b-table-simple
      bordered
      small
      responsive
      class="evaluation-criterias-table"
      v-if="institution && institution.show_evaluation_criteria_macro"
    >
      <b-thead class="evaluation-criterias-header">
        <b-th class="th-custom">
          {{
            $getVisibleNames(
              "teaching.evaluationcriteriamacro",
              true,
              "Criterio de Evaluación Macro"
            )
          }}
        </b-th>
        <b-th class="text-center align-middle"
          >Instrumentos de Observación</b-th
        >
        <b-th class="text-center align-middle">Instrumentos Escritos</b-th>
        <b-th class="text-center align-middle">Menciones</b-th>
      </b-thead>
      <b-tbody>
        <b-tr
          class="ec-macro-tr"
          v-for="ec_macro in evaluationCriteriaMacroList"
          :key="ec_macro.id"
        >
          <b-td
            class="ec-macro-td align-middle"
            :class="{
              'criteria-without-mention': !appliedCriteriaMacroAmount(
                ec_macro.id
              ),
            }"
          >
            <span class="evaluation-criterias-macros-order">
              {{ ec_macro.order + ". " }}
            </span>
            <SentenceContainer
              class="text-class"
              v-b-tooltip.left.v-secondary.noninteractive="
                `
                ${$getVisibleNames(
                  'teaching.evaluationcriteriamacro',
                  true,
                  'Criterio de Evaluación Macro'
                )}`
              "
              :Sentence="ec_macro"
              :boundedTextContainer="true"
              :Large="140"
            ></SentenceContainer>
          </b-td>
          <b-td class="align-middle">
            {{
              observationInstrumentECMacroAmount(ec_macro.id)
                ? observationInstrumentECMacroAmount(ec_macro.id)
                : "-"
            }}
          </b-td>
          <b-td class="align-middle">
            {{
              questionTestsECMacroAmount(ec_macro.id)
                ? questionTestsECMacroAmount(ec_macro.id)
                : "-"
            }}
          </b-td>
          <b-td class="ec-micro-td text-center align-middle">
            <b-badge
              class="custom-badge"
              :class="{ 'bg-red': !appliedCriteriaMacroAmount(ec_macro.id) }"
              >{{ appliedCriteriaMacroAmount(ec_macro.id) }}</b-badge
            >
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <template
      v-if="
        !evaluationCriteriaMacroList.length &&
        institution &&
        institution.show_evaluation_criteria_macro
      "
    >
      <div class="text-center my-3">
        <b-icon class="mr-1" icon="exclamation-circle"></b-icon> No existen
        {{
          $getVisibleNames(
            "teaching.evaluationcriteriamacro",
            true,
            "Criterio de Evaluación Macro"
          ).toLowerCase()
        }}
        asociados a esta evaluación.
      </div>
    </template>
    <b-table-simple
      bordered
      small
      responsive
      class="evaluation-criterias-table"
    >
      <b-thead class="evaluation-criterias-header">
        <b-th class="th-custom">
          {{
            $getVisibleNames(
              "teaching.evaluationcriteriamicro",
              true,
              "Criterio de Evaluación Micro"
            )
          }}
        </b-th>
        <b-th class="text-center align-middle"
          >Instrumentos de Observación</b-th
        >
        <b-th class="text-center align-middle">Instrumentos Escritos</b-th>
        <b-th class="text-center align-middle">Menciones</b-th>
      </b-thead>
      <b-tbody>
        <template v-for="item in filterECMicro">
          <b-tr
            class="ec-micro-tr"
            v-for="ec_micro in item.options"
            :key="ec_micro.id"
          >
            <b-td
              class="evaluation-criterias-micros align-middle"
              :class="{
                'criteria-without-mention': !appliedCriteriaMicroAmount(
                  ec_micro.id
                ),
              }"
            >
              <span class="evaluation-criterias-micros-order">
                {{ item ? item.order : "" }}.{{
                  ec_micro ? ec_micro.order : ""
                }}
              </span>
              <SentenceContainer
                class="text-class"
                v-b-tooltip.left.v-secondary.noninteractive="
                  `
                ${$getVisibleNames(
                  'teaching.evaluationcriteriamicro',
                  true,
                  'Criterio de Evaluación Micro'
                )}`
                "
                :Sentence="ec_micro"
                :boundedTextContainer="true"
                :Large="140"
              ></SentenceContainer>
            </b-td>
            <b-td class="align-middle">
              {{
                observationInstrumentECMicroAmount(ec_micro.id)
                  ? observationInstrumentECMicroAmount(ec_micro.id)
                  : "-"
              }}
            </b-td>
            <b-td class="align-middle">
              {{
                questionTestsECMicroAmount(ec_micro.id)
                  ? questionTestsECMicroAmount(ec_micro.id)
                  : "-"
              }}
            </b-td>
            <b-td class="ec-micro-td text-center align-middle">
              <b-badge
                class="custom-badge"
                :class="{
                  'bg-red': !appliedCriteriaMicroAmount(ec_micro.id),
                }"
                >{{ appliedCriteriaMicroAmount(ec_micro.id) }}</b-badge
              >
            </b-td>
          </b-tr>
        </template>
      </b-tbody>
    </b-table-simple>
    <template v-if="!filterECMicro.length">
      <div class="text-center my-3">
        <b-icon class="mr-1" icon="exclamation-circle"></b-icon> No existen
        {{
          $getVisibleNames(
            "teaching.evaluationcriteriamicro",
            true,
            "Criterio de Evaluación Micro"
          ).toLowerCase()
        }}
        asociados a esta evaluación.
      </div>
    </template>
    <div class="unassociated-criterias-table">
      <b-table-simple
        bordered
        small
        responsive
        v-if="questions && observationInstances"
      >
        <b-thead>
          <b-th class="text-center align-middle bg-variant-secondary"
            >Total de preguntas en instrumentos escritos</b-th
          >
          <b-th class="text-center align-middle bg-variant-secondary"
            >Preguntas no asociadas a
            {{
              $getVisibleNames(
                "manual.criterio_de_evaluacion",
                true,
                "Criterios de Evaluación"
              )
            }}</b-th
          >
          <b-th class="text-center align-middle bg-variant-secondary">%</b-th>
          <b-th class="text-center align-middle bg-variant-secondary"
            >Total de
            {{ $getVisibleNames("manual.dimension", true, "Dimensiones") }}
            en instrumentos de observación</b-th
          >
          <b-th class="text-center align-middle bg-variant-secondary"
            >{{ $getVisibleNames("manual.dimension", true, "Dimensiones") }}
            no asociadas a
            {{
              $getVisibleNames(
                "manual.criterio_de_evaluacion",
                true,
                "Criterios de Evaluación"
              )
            }}</b-th
          >
          <b-th class="text-center align-middle bg-variant-secondary">%</b-th>
        </b-thead>
        <b-tbody>
          <b-td class="text-center align-middle py-3 px-2">{{
            questions ? questions.length : ""
          }}</b-td>
          <b-td class="text-center align-middle py-3 px-2">{{
            questionsWithoutCriteria
          }}</b-td>
          <b-td
            class="text-center align-middle py-3 px-2"
            :class="{
              'text-danger':
                questions &&
                questions.length &&
                ((questionsWithoutCriteria / questions.length) * 100).toFixed(
                  1
                ) > 50
                  ? true
                  : false,
            }"
          >
            {{
              questions && questions.length
                ? ((questionsWithoutCriteria / questions.length) * 100).toFixed(
                    1
                  ) + "%"
                : "-"
            }}
          </b-td>
          <b-td class="text-center align-middle py-3 px-2">{{
            observationInstances ? observationInstances.length : ""
          }}</b-td>
          <b-td class="text-center align-middle py-3 px-2">{{
            dimensionsWithoutCriteria
          }}</b-td>
          <b-td
            class="text-center align-middle py-3 px-2"
            :class="{
              'text-danger':
                observationInstances &&
                observationInstances.length &&
                (
                  (dimensionsWithoutCriteria / observationInstances.length) *
                  100
                ).toFixed(1) > 50
                  ? true
                  : false,
            }"
            >{{
              observationInstances && observationInstances.length
                ? (
                    (dimensionsWithoutCriteria / observationInstances.length) *
                    100
                  ).toFixed(1) + "%"
                : "-"
            }}</b-td
          >
        </b-tbody>
      </b-table-simple>
    </div>
  </div>
</template>
<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "AppliedEvaluationCriterias",
  components: {
    SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
  },
  props: {
    evaluation_id: {},
    matter: { required: true },
  },
  data() {
    return { evaluations_list: null };
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      evaluationCriteriasMacros: names.EVALUATION_CRITERIAS_MACROS,
      evaluationCriteriasMicros: names.EVALUATION_CRITERIAS,
      section_evaluations: names.SECTION_EVALUATIONS,
      sections: names.SECTIONS,
      studyUnits: names.STUDY_UNITS,
      questions: names.QUESTIONS,
      observationInstances: names.OBSERVATION_INSTANCES,
      questionTests: names.QUESTION_TESTS,
      observationInstruments: names.OBSERVATION_INSTRUMENTS,
    }),
    evaluationCriteriaMacroList() {
      if (!this.matter) return [];
      let list = this.evaluationCriteriasMacros
        .filter((x) => x.matter == this.matter.matter)
        .sort(function (a, b) {
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
      return list;
    },
    filterECMicro() {
      if (!this.matter) return [];
      let list = [];
      this.studyUnits.forEach((study_unit) => {
        const evaluation_criterias = this.evaluationCriteriasMicros.filter(
          (x) =>
            x.study_unit == study_unit.id &&
            study_unit.matter == this.matter.matter
        );
        if (evaluation_criterias.length > 0)
          list.push({
            id: study_unit.id,
            name: study_unit.name,
            order: study_unit.order,
            options: this.evaluationCriteriasMicros
              .filter((x) => x.study_unit == study_unit.id)
              .sort(function (a, b) {
                if (a.order > b.order) return 1;
                if (a.order < b.order) return -1;
                return a.full_sentence
                  .toLowerCase()
                  .localeCompare(b.full_sentence.toLowerCase());
              }),
          });
      });
      return list;
    },
    questionsWithoutCriteria() {
      let count = 0;
      this.questions.forEach((question) => {
        if (
          !question.evaluation_criterias_macro.length &&
          !question.evaluation_criterias_micro.length
        ) {
          count++;
        }
      });
      return count;
    },
    dimensionsWithoutCriteria() {
      let count = 0;
      this.observationInstances.forEach((dimension) => {
        if (
          !dimension.evaluation_criterias_macro.length &&
          !dimension.evaluation_criterias_micro.length
        ) {
          count++;
        }
      });
      return count;
    },
  },
  methods: {
    appliedCriteriaMacroAmount(criteria_id) {
      let count = 0;
      this.questions.forEach((question) => {
        if (question.evaluation_criterias_macro.includes(criteria_id)) count++;
      });
      this.observationInstances.forEach((dimension) => {
        if (dimension.evaluation_criterias_macro.includes(criteria_id)) count++;
      });
      return count;
    },
    appliedCriteriaMicroAmount(criteria_id) {
      let count = 0;
      this.questions.forEach((question) => {
        if (question.evaluation_criterias_micro.includes(criteria_id)) count++;
      });
      this.observationInstances.forEach((dimension) => {
        if (dimension.evaluation_criterias_micro.includes(criteria_id)) count++;
      });
      return count;
    },

    observationInstrumentECMacroAmount(criteria_id) {
      let count = 0;
      this.observationInstances.forEach((dimension) => {
        if (dimension.evaluation_criterias_macro.includes(criteria_id)) count++;
      });
      return count;
    },
    questionTestsECMacroAmount(criteria_id) {
      let count = 0;
      this.questions.forEach((question) => {
        if (question.evaluation_criterias_macro.includes(criteria_id)) count++;
      });
      return count;
    },
    observationInstrumentECMicroAmount(criteria_id) {
      let count = 0;
      this.observationInstances.forEach((dimension) => {
        if (dimension.evaluation_criterias_micro.includes(criteria_id)) count++;
      });
      return count;
    },
    questionTestsECMicroAmount(criteria_id) {
      let count = 0;
      this.questions.forEach((question) => {
        if (question.evaluation_criterias_micro.includes(criteria_id)) count++;
      });
      return count;
    },

    fetchEvaluationsList() {
      this.$restful
        .Get(
          `/teaching/get_user_evaluations_per_epm/?egress_profile_matter=${this.matter.id}`
        )
        .then((response) => {
          this.evaluations_list = response;
          response.forEach((x) => {
            this.$store.dispatch(
              names.FETCH_SECTION_EVALUATIONS,
              x.evaluation.id
            );
            response.forEach((evaluation) => {
              evaluation.evaluation.question_tests.forEach((question_test) => {
                this.$store.dispatch(names.FETCH_QUESTIONS, question_test.id);
              });
              evaluation.evaluation.observation_instruments.forEach(
                (observation_instrument) => {
                  this.$store.dispatch(
                    names.FETCH_OBSERVATION_INSTANCES,
                    observation_instrument.id
                  );
                }
              );
            });
          });
        });
    },
  },
  created() {
    if (this.matter) {
      this.fetchEvaluationsList();
    }
  },
  mounted() {},
};
</script>
<style scoped>
.th-custom {
  width: 60%;
}
.evaluation-criterias-table {
  width: 100%;
  margin-bottom: 0.8rem;
}
.evaluation-criterias-header {
  background-color: var(--primary-color);
}
.ec-macro-tr:nth-of-type(odd),
.ec-micro-tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.text-class {
  padding-top: 0.1rem;
}
.evaluation-criterias-macros-order,
.evaluation-criterias-micros-order {
  font-weight: 100;
}
.ec-macro-td {
  display: flex;
  text-align: left;
  padding: 0.4rem 0.2rem !important;
}
.criteria-without-mention {
  color: #dc3545 !important;
  font-weight: bold;
}
.evaluation-criterias-micros {
  display: flex;
  text-align: left;
  width: 100%;
  padding: 0.4rem 0.2rem !important;
}
.custom-badge {
  font-size: 11pt;
  padding: 0.4rem 0.6rem;
}
.bg-red {
  background-color: #dc3545 !important;
}
.bg-variant-secondary {
  background: var(--primary-hover-color);
}
</style>
