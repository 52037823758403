var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.institution && _vm.institution.show_evaluation_criteria_macro)?_c('b-table-simple',{staticClass:"evaluation-criterias-table",attrs:{"bordered":"","small":"","responsive":""}},[_c('b-thead',{staticClass:"evaluation-criterias-header"},[_c('b-th',{staticClass:"th-custom"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "teaching.evaluationcriteriamacro", true, "Criterio de Evaluación Macro" ))+" ")]),_c('b-th',{staticClass:"text-center align-middle"},[_vm._v("Instrumentos de Observación")]),_c('b-th',{staticClass:"text-center align-middle"},[_vm._v("Instrumentos Escritos")]),_c('b-th',{staticClass:"text-center align-middle"},[_vm._v("Menciones")])],1),_c('b-tbody',_vm._l((_vm.evaluationCriteriaMacroList),function(ec_macro){return _c('b-tr',{key:ec_macro.id,staticClass:"ec-macro-tr"},[_c('b-td',{staticClass:"ec-macro-td align-middle",class:{
            'criteria-without-mention': !_vm.appliedCriteriaMacroAmount(
              ec_macro.id
            ),
          }},[_c('span',{staticClass:"evaluation-criterias-macros-order"},[_vm._v(" "+_vm._s(ec_macro.order + ". ")+" ")]),_c('SentenceContainer',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.left.v-secondary.noninteractive",value:(
              `
              ${_vm.$getVisibleNames(
                'teaching.evaluationcriteriamacro',
                true,
                'Criterio de Evaluación Macro'
              )}`
            ),expression:"\n              `\n              ${$getVisibleNames(\n                'teaching.evaluationcriteriamacro',\n                true,\n                'Criterio de Evaluación Macro'\n              )}`\n            ",modifiers:{"left":true,"v-secondary":true,"noninteractive":true}}],staticClass:"text-class",attrs:{"Sentence":ec_macro,"boundedTextContainer":true,"Large":140}})],1),_c('b-td',{staticClass:"align-middle"},[_vm._v(" "+_vm._s(_vm.observationInstrumentECMacroAmount(ec_macro.id) ? _vm.observationInstrumentECMacroAmount(ec_macro.id) : "-")+" ")]),_c('b-td',{staticClass:"align-middle"},[_vm._v(" "+_vm._s(_vm.questionTestsECMacroAmount(ec_macro.id) ? _vm.questionTestsECMacroAmount(ec_macro.id) : "-")+" ")]),_c('b-td',{staticClass:"ec-micro-td text-center align-middle"},[_c('b-badge',{staticClass:"custom-badge",class:{ 'bg-red': !_vm.appliedCriteriaMacroAmount(ec_macro.id) }},[_vm._v(_vm._s(_vm.appliedCriteriaMacroAmount(ec_macro.id)))])],1)],1)}),1)],1):_vm._e(),(
      !_vm.evaluationCriteriaMacroList.length &&
      _vm.institution &&
      _vm.institution.show_evaluation_criteria_macro
    )?[_c('div',{staticClass:"text-center my-3"},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"exclamation-circle"}}),_vm._v(" No existen "+_vm._s(_vm.$getVisibleNames( "teaching.evaluationcriteriamacro", true, "Criterio de Evaluación Macro" ).toLowerCase())+" asociados a esta evaluación. ")],1)]:_vm._e(),_c('b-table-simple',{staticClass:"evaluation-criterias-table",attrs:{"bordered":"","small":"","responsive":""}},[_c('b-thead',{staticClass:"evaluation-criterias-header"},[_c('b-th',{staticClass:"th-custom"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "teaching.evaluationcriteriamicro", true, "Criterio de Evaluación Micro" ))+" ")]),_c('b-th',{staticClass:"text-center align-middle"},[_vm._v("Instrumentos de Observación")]),_c('b-th',{staticClass:"text-center align-middle"},[_vm._v("Instrumentos Escritos")]),_c('b-th',{staticClass:"text-center align-middle"},[_vm._v("Menciones")])],1),_c('b-tbody',[_vm._l((_vm.filterECMicro),function(item){return _vm._l((item.options),function(ec_micro){return _c('b-tr',{key:ec_micro.id,staticClass:"ec-micro-tr"},[_c('b-td',{staticClass:"evaluation-criterias-micros align-middle",class:{
              'criteria-without-mention': !_vm.appliedCriteriaMicroAmount(
                ec_micro.id
              ),
            }},[_c('span',{staticClass:"evaluation-criterias-micros-order"},[_vm._v(" "+_vm._s(item ? item.order : "")+"."+_vm._s(ec_micro ? ec_micro.order : "")+" ")]),_c('SentenceContainer',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.left.v-secondary.noninteractive",value:(
                `
              ${_vm.$getVisibleNames(
                'teaching.evaluationcriteriamicro',
                true,
                'Criterio de Evaluación Micro'
              )}`
              ),expression:"\n                `\n              ${$getVisibleNames(\n                'teaching.evaluationcriteriamicro',\n                true,\n                'Criterio de Evaluación Micro'\n              )}`\n              ",modifiers:{"left":true,"v-secondary":true,"noninteractive":true}}],staticClass:"text-class",attrs:{"Sentence":ec_micro,"boundedTextContainer":true,"Large":140}})],1),_c('b-td',{staticClass:"align-middle"},[_vm._v(" "+_vm._s(_vm.observationInstrumentECMicroAmount(ec_micro.id) ? _vm.observationInstrumentECMicroAmount(ec_micro.id) : "-")+" ")]),_c('b-td',{staticClass:"align-middle"},[_vm._v(" "+_vm._s(_vm.questionTestsECMicroAmount(ec_micro.id) ? _vm.questionTestsECMicroAmount(ec_micro.id) : "-")+" ")]),_c('b-td',{staticClass:"ec-micro-td text-center align-middle"},[_c('b-badge',{staticClass:"custom-badge",class:{
                'bg-red': !_vm.appliedCriteriaMicroAmount(ec_micro.id),
              }},[_vm._v(_vm._s(_vm.appliedCriteriaMicroAmount(ec_micro.id)))])],1)],1)})})],2)],1),(!_vm.filterECMicro.length)?[_c('div',{staticClass:"text-center my-3"},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"exclamation-circle"}}),_vm._v(" No existen "+_vm._s(_vm.$getVisibleNames( "teaching.evaluationcriteriamicro", true, "Criterio de Evaluación Micro" ).toLowerCase())+" asociados a esta evaluación. ")],1)]:_vm._e(),_c('div',{staticClass:"unassociated-criterias-table"},[(_vm.questions && _vm.observationInstances)?_c('b-table-simple',{attrs:{"bordered":"","small":"","responsive":""}},[_c('b-thead',[_c('b-th',{staticClass:"text-center align-middle bg-variant-secondary"},[_vm._v("Total de preguntas en instrumentos escritos")]),_c('b-th',{staticClass:"text-center align-middle bg-variant-secondary"},[_vm._v("Preguntas no asociadas a "+_vm._s(_vm.$getVisibleNames( "manual.criterio_de_evaluacion", true, "Criterios de Evaluación" )))]),_c('b-th',{staticClass:"text-center align-middle bg-variant-secondary"},[_vm._v("%")]),_c('b-th',{staticClass:"text-center align-middle bg-variant-secondary"},[_vm._v("Total de "+_vm._s(_vm.$getVisibleNames("manual.dimension", true, "Dimensiones"))+" en instrumentos de observación")]),_c('b-th',{staticClass:"text-center align-middle bg-variant-secondary"},[_vm._v(_vm._s(_vm.$getVisibleNames("manual.dimension", true, "Dimensiones"))+" no asociadas a "+_vm._s(_vm.$getVisibleNames( "manual.criterio_de_evaluacion", true, "Criterios de Evaluación" )))]),_c('b-th',{staticClass:"text-center align-middle bg-variant-secondary"},[_vm._v("%")])],1),_c('b-tbody',[_c('b-td',{staticClass:"text-center align-middle py-3 px-2"},[_vm._v(_vm._s(_vm.questions ? _vm.questions.length : ""))]),_c('b-td',{staticClass:"text-center align-middle py-3 px-2"},[_vm._v(_vm._s(_vm.questionsWithoutCriteria))]),_c('b-td',{staticClass:"text-center align-middle py-3 px-2",class:{
            'text-danger':
              _vm.questions &&
              _vm.questions.length &&
              ((_vm.questionsWithoutCriteria / _vm.questions.length) * 100).toFixed(
                1
              ) > 50
                ? true
                : false,
          }},[_vm._v(" "+_vm._s(_vm.questions && _vm.questions.length ? ((_vm.questionsWithoutCriteria / _vm.questions.length) * 100).toFixed( 1 ) + "%" : "-")+" ")]),_c('b-td',{staticClass:"text-center align-middle py-3 px-2"},[_vm._v(_vm._s(_vm.observationInstances ? _vm.observationInstances.length : ""))]),_c('b-td',{staticClass:"text-center align-middle py-3 px-2"},[_vm._v(_vm._s(_vm.dimensionsWithoutCriteria))]),_c('b-td',{staticClass:"text-center align-middle py-3 px-2",class:{
            'text-danger':
              _vm.observationInstances &&
              _vm.observationInstances.length &&
              (
                (_vm.dimensionsWithoutCriteria / _vm.observationInstances.length) *
                100
              ).toFixed(1) > 50
                ? true
                : false,
          }},[_vm._v(_vm._s(_vm.observationInstances && _vm.observationInstances.length ? ( (_vm.dimensionsWithoutCriteria / _vm.observationInstances.length) * 100 ).toFixed(1) + "%" : "-"))])],1)],1):_vm._e()],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }